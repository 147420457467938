import React from 'react'

const Footer = props => (
  <footer id="footer">
    <ul className="icons">
      <li>
        <a href="https://twitter.com/travelonux" className="icon brands alt fa-twitter">
          <span className="label">Twitter</span>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/travelonux" className="icon brands alt fa-facebook-f">
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/travelonux" className="icon brands alt fa-linkedin-in">
          <span className="label">LinkedIn</span>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/travelonux/" className="icon brands alt fa-instagram">
          <span className="label">Instagram</span>
        </a>
      </li>
      <li>
        <a href="https://github.com/travelonux" className="icon brands alt fa-github">
          <span className="label">GitHub</span>
        </a>
      </li>
      <li>
        <a href="/contact" className="icon solid alt fa-envelope">
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
    <ul className="copyright">
      <li>&copy; CORPOIT S.A. All rights reserved.</li>
      <li>
        Designed & Developed: <a href="http://travelonux.com">TRAVELONUX</a>
      </li>
      <li>
        Thanks to: <a href="http://html5up.net">HTML5 UP</a>
      </li>
    </ul>
  </footer>
)

export default Footer
